import AppLayout from '@/views/layouts/AppLayout.vue';
import Login from '@/views/auth/TheLogin.vue';

export default [
  {
    path: '/peng/login',
    name: 'PENG_Login',
    component: Login,
    meta: {
      hidden: true,
    },
  },
  {
    path: '/peng',
    redirect: { name: 'PENG_Index' },
    meta: {
      hidden: true,
    },
  },
  {
    path: '/peng/index',
    component: AppLayout,
    redirect: { name: 'PENG_Index' },
    children: [
      {
        path: '/peng/index',
        name: 'PENG_Index',
        component: () => import('@/views/peng/common/VHomepage.vue'),
        meta: {
          icon: 'home',
          auth: ['root', 'admin', 'general', '', 'ops'],
        },
      },
    ],
  },
  {
    path: '/peng/requirements_manage',
    name: 'PENG_RequirementsManage',
    component: AppLayout,
    redirect: { name: 'PENG_Requirements' },
    meta: {
      hasMulSub: true,
    },
    children: [
      {
        path: '/peng/requirements_manage/requirements',
        name: 'PENG_Requirements',
        component: () => import('@/views/peng/modules/requirement/Requirements.vue'),
        meta: {
          icon: 'add',
          auth: ['root', 'admin', 'general', ''],
        },
      },
      {
        path: '/peng/requirements_manage/sync',
        name: 'PENG_SyncRequirements',
        component: () => import('@/views/peng/modules/requirement/SyncRequirements.vue'),
        meta: {
          icon: 'add',
          auth: ['root', 'admin', 'general', ''],
        },
      },
    ],
  },
];
