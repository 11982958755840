// 系统相关的常量配置
export const SYSTEM_MODES = {
  DS: 'DS',
  PENG: 'PENG'
};

export const PENG_PATH_PREFIX = '/peng';

export const DEFAULT_SYSTEM_MODE = SYSTEM_MODES.DS;

/**
 * 根据当前路径判断系统模式
 * @param {string} path - 当前路径
 * @returns {string} 系统模式
 */
export const getSystemModeByPath = (path) => {
  return path.includes(PENG_PATH_PREFIX) ? SYSTEM_MODES.PENG : SYSTEM_MODES.DS;
}; 