import * as types from './types';
import { SYSTEM_MODES, DEFAULT_SYSTEM_MODE } from '@/config/system';

const state = {
  isInIframe: false,
  lang: localStorage.getItem('PENGUIN-FE_LANGUAGE') || 'zh-CN',
  systemMode: localStorage.getItem('PENGUIN-FE_SYSTEM_MODE') || DEFAULT_SYSTEM_MODE,
};

const getters = {};

const actions = {
  updateIsInIframe({ commit }, value) {
    commit(types.UPDATE_IS_IN_IFRAME, { value });
  },
  changeLang({ commit }, value) {
    commit(types.CHANGE_LANG, { value });
  },
  setSystemMode({ commit }, mode) {
    commit(types.SET_SYSTEM_MODE, { mode });
  },
};

const mutations = {
  [types.UPDATE_IS_IN_IFRAME](state, { value }) {
    state.isInIframe = value;
  },
  [types.CHANGE_LANG](state, { value }) {
    state.lang = value;
  },
  [types.SET_SYSTEM_MODE](state, { mode }) {
    state.systemMode = mode;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
