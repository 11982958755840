<template>
  <el-aside width="">
    <el-menu
      default-active="1-4-1"
      class="el-menu-vertical-demo"
      :collapse="!drawer"
      :default-openeds="['1']"
    >

      <el-submenu
        v-if="hasLogin && !isOpsUser"
        index="1"
        :style="{ width: drawer ? '199px' : '60px' }"
      >
        <template slot="title">
          <i class="el-icon-tickets"></i>
          <span slot="title">需求管理</span>
        </template>

        <el-menu-item-group>
          <span slot="title">Requirements</span>
          <el-menu-item index="1-1" @click="routeTo('PENG_Requirements')">需求</el-menu-item>
          <el-menu-item index="1-2" @click="routeTo('PENG_SyncRequirements')" :disabled="!mustAboveNormal">拉表</el-menu-item>
        </el-menu-item-group>

      </el-submenu>

    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: 'AppSidebar',
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    currentUserRole() {
      return this.$auth.user().role;
    },
    currentRoute() {
      console.log(this.$route.name);
      return this.$route.name;
    },
    mustAboveNormal() {
      return this.currentUserRole !== '';
    },
    user() {
      return this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
    },
    hasLogin() {
      return Object.keys(this.user).length !== 0;
    },
    isOpsUser() {
      return this.currentUserRole.toLowerCase() === 'ops' || this.user.username === 'testteam';
    },
  },
  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
    routeTo(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
</style>
