<template>
  <el-button
    v-if="isManager"
    size="small"
    :type="isLoginPage ? '' : buttonType"
    @click="toggleSystemMode"
    :class="[
      'bg-transparent border-transparent font-bold italic underline decoration-double',
      isLoginPage ? 'text-[#237AE0] hover:text-[#237AE0]' : ''
    ]"
  >
    {{ buttonText }}
  </el-button>
</template>

<script>
import { SYSTEM_MODES, PENG_PATH_PREFIX } from '@/config/system';

export default {
  name: 'SystemModeSwitch',
  data() {
    return {
      SYSTEM_MODES,
    };
  },
  computed: {
    systemMode() {
      return this.$store.state.common.systemMode;
    },
    buttonType() {
      return this.systemMode === this.SYSTEM_MODES.PENG ? 'danger' : 'primary';
    },
    isLoginPage() {
      return this.$route.path === '/login' || this.$route.path === '/peng/login';
    },
    buttonText() {
      return this.systemMode === this.SYSTEM_MODES.PENG ? 'Switch back to DS' : 'Beta: Switch to PENG';
    },
    isManager() {
      const user = this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
      let visible_list = ['liangzhichong', 'pengshuxia', 'wuling'];
      return visible_list.includes(user.username);
    }
  },
  methods: {
    toggleSystemMode() {
      const newMode = this.systemMode === this.SYSTEM_MODES.PENG ? this.SYSTEM_MODES.DS : this.SYSTEM_MODES.PENG;
      
      // 直接更新状态和跳转
      this.$store.dispatch('setSystemMode', newMode);
      const currentPath = this.$route.path;
      if (newMode === this.SYSTEM_MODES.PENG) {
        this.$router.push(PENG_PATH_PREFIX + currentPath);
      } else {
        this.$router.push(currentPath.replace(PENG_PATH_PREFIX + '/', '/'));
      }
    },
  },
};
</script>

<style scoped>
.el-button {
  min-width: 100px;
}

.el-button.is-primary,
.el-button.is-danger {
  color: white !important;
}

.el-button.is-primary:hover,
.el-button.is-danger:hover {
  color: white !important;
}

/* 登录页面的特殊样式 */
.el-button.is-primary:not(.bg-transparent) {
  color: #2563eb !important;
}

.el-button.is-primary:not(.bg-transparent):hover {
  color: #2563eb !important;
}
</style> 