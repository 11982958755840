<template>
  <div class="panel-wrapper">
    <span class="logo">
      <img
        :src=getLogo()
        alt="Logo"
      >
    </span>

    <div class="slogan-wrapper" :class="{ 'peng-mode': systemMode === SYSTEM_MODES.PENG }">
      <div class="slogan">
        <span v-if="lang === 'en'">
          <template v-if="systemMode === SYSTEM_MODES.PENG">
            Platform for Efficient <br>Next Generation
          </template>
          <template v-else>
            Peng's <br>United Integrate Network
          </template>
        </span>
        <span v-else style="font-size: 50px;">
          <template v-if="systemMode === SYSTEM_MODES.PENG">
            PENG 管理平台
          </template>
          <template v-else>
            Digital Shelf 管理平台
          </template>
        </span>
      </div>
    </div>

    <div class="panel-content">
      <div class="flex items-center absolute top-8 right-8">
        <system-mode-switch class="mr-4"/>
        <base-langbar/>
      </div>
      <div class="login-con">
        <el-form class="frame">
          <h1 v-if="!isMobile">
            {{ $t('common.loginN') }}
          </h1>
          <!-- <p>Don't have an account? <el-button type="text">Create your account</el-button></p> -->
          <el-form-item>
            <el-input
              size="large"
              clearable
              v-model="form.username"
              @keyup.enter.native="login"
              :placeholder="$t('common.username')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              size="large"
              clearable
              v-model="form.password"
              type="password"
              @keyup.enter.native="login"
              :placeholder="$t('common.password')"
            ></el-input>
          </el-form-item>
          <el-form-item class="mb-0">
            <div class="flex items-center justify-between">
              <el-button
                type="text"
                @click="redirectForgotPassword"
              >
                {{ $t('common.forgetPassword') }}
              </el-button>
              <el-button
                class="el-button--center w-1/2"
                size="large"
                type="primary"
                :disabled="loginLoading"
                @click="login"
              >
                {{ $t('common.login') }}
                <i
                  v-if="loginLoading"
                  class="el-icon-loading"
                ></i>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <el-footer>
        {{ $t('common.copyrightMessage', { currentYear }) }}
      </el-footer>
    </div>
  </div>
</template>

<script>
import { isEmptyObject, isMobile } from '@/utils/util';
import BaseLangbar from '@/components/widgets/BaseLangbar.vue';
import SystemModeSwitch from '@/components/widgets/SystemModeSwitch.vue';
import logo_svg from '@/assets/logo-login.svg';
import { SYSTEM_MODES, PENG_PATH_PREFIX, getSystemModeByPath } from '@/config/system';

export default {
  name: 'Login',
  components: {
    BaseLangbar,
    SystemModeSwitch,
  },
  data() {
    return {
      currentYear: (new Date()).getFullYear(),
      routeFrom: {},
      form: {
        username: '',
        password: '',
      },
      loginLoading: false,
      currentLang: this.$i18n.locale,
      SYSTEM_MODES,
    };
  },
  computed: {
    systemMode() {
      return this.$store.state.common.systemMode;
    },
    isMobile() {
      return isMobile();
    },
    lang() {
      return this.$store.state.common.lang;
    },
  },
  created() {
    // 根据当前路径设置系统模式
    const currentMode = getSystemModeByPath(this.$route.path);
    this.$store.dispatch('setSystemMode', currentMode);
  },
  /* eslint-disable */
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeFrom = from.name ? from : vm.$route.query && vm.$route.query.redirectedFrom ?
        { path: vm.$route.query.redirectedFrom } : null;
    });
  },
  methods: {
    login() {
      if (!this.form.password || !this.form.username) {
        return;
      }

      this.loginLoading = true;
      this.$auth.login(this.form).then(() => {
        this.$autoCommitHttpService.login(this.form);
        try {
          if (this.systemMode === this.SYSTEM_MODES.PENG) {
            this.$router.push({ name: 'PENG_Index' });
          } else {
            this.$router.push({ name: 'Index' });
          }
        } catch (err) {
          if (this.systemMode === this.SYSTEM_MODES.PENG) {
            this.$router.push({ path: '/peng/index' });
          } else {
            this.$router.push({ path: '/' });
          }
        }
      }).catch((res) => {
        console.error('login failed', res);
        const { data } = res.data;
        const { err_code, reason } = res.data;

        if (!data || isEmptyObject(data)) {
          this.$message.error(this.$t(reason.toLowerCase() === 'not bind to current app' ? 'common.notBindApp' : 'common.incorrect_username_password'));
          return;
        }

        const maxAttempts = data.login_max_attempts;
        const failedCount = data.login_failed_count;
        const enableLoginLock = data.enable_login_lock;
        const remainedCount = maxAttempts - failedCount;

        // 01A0108 - Invalid login or password - 密码错误
        // 01A0110 - Unable to login by password - 账号不存在
        // 01A0105 - Account is locked - 账号被锁定
        if (err_code === '01A0110') {
          // 账号不存在也提示被锁定，防止恶意扫描哪些账号存在
          this.$message.error(this.$t('common.account_is_locked'));
          return;
        }

        if (enableLoginLock && err_code === '01A0105') {
          this.$message.error(this.$t('common.account_is_locked'));
          return;
        }

        if (enableLoginLock && maxAttempts && failedCount !== null && failedCount <= maxAttempts) {
          if (this.$locale.current() === 'en' && remainedCount === 1) {
            this.$message.error('Invalid Username or Password. last login Remained.');
          } else {
            this.$message.error(this.$t('common.invalid_password_username_number', { number: remainedCount }));
          }
          return;
        }

        this.$message.error(this.$t('common.invalid_password_username'));
      }).finally(() => {
        this.loginLoading = false;
      });
    },
    redirectForgotPassword() {
      window.open(`${process.env.VUE_APP_HAMLET_URL}/forgot_password?app_key=${process.env.VUE_APP_APP_KEY}&callback_url=${document.location.href}`);
    },
    getLogo() {
      return logo_svg;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_login.scss";
</style>
