<template>
  <div class="change-lang inline-flex items-center">
    <span
      @click="switchLang('zh-CN')"
      :class="['lang-text', { 'active-lang': currentLang === 'zh-CN' }]"
    >
      中文
    </span> /
    <span
      @click="switchLang('en')"
      :class="['lang-text', { 'active-lang': currentLang === 'en' }]"
    >
      En
    </span>
  </div>
</template>

<script>
export default {
  name: 'LangBar',
  data() {
    return {
      currentLang: this.$i18n.locale,
    };
  },
  methods: {
    switchLang(lang = 'zh-CN') {
      this.currentLang = lang;
      this.$locale.use(lang);
      this.$moment.locale(lang);
      localStorage.setItem('PENGUIN-FE_LANGUAGE', lang);
      this.$store.dispatch('changeLang', lang);
    },
  },
};
</script>

<style scoped>
.change-lang {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.lang-text {
  display: inline-flex;
  white-space: nowrap;
  writing-mode: horizontal-tb;
  min-width: fit-content;
}
</style>
