/* eslint-disable camelcase */
import request from './utils';

const urlPrefix = '/api/peng';
const urls = {
  // requirements
  pengSyncRequirements: '/requirements/sync',
  pengGetTaskState: '/requirements/task_running_state',
  pengGetSupportedDocs: '/requirements/supported_docs',
  pengGetRunningTasks: '/requirements/running_tasks',
};

Object.keys(urls).map((url) => {
  urls[url] = urlPrefix + urls[url];
  return url;
});

const API = new function PengAPI() {
  // requirements
  this.pengSyncRequirements = (params) => request.post(urls.pengSyncRequirements, params);
  this.pengGetTaskState = (taskId) => request.get(urls.pengGetTaskState + `/${taskId}`);
  this.pengGetSupportedDocs = () => request.get(urls.pengGetSupportedDocs);
  this.pengGetRunningTasks = () => request.get(urls.pengGetRunningTasks);
}();

export default API;