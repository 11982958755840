<template>
  <el-main :style="mainStyle">
    
      <transition
        name="move"
        mode="out-in"
      >

        <router-view />
      
      </transition>
    
  </el-main>
</template>

<script>
import peng_index_bg from '@/assets/penguins/pengHomePage.jpg';
import { SYSTEM_MODES } from '@/config/system';

export default {
  name: 'AppMain',
  data() {
    return {
      peng_index_bg,
      SYSTEM_MODES,
    }
  },
  computed: {
    mainStyle() {
      const isPengMode = this.$store.state.common.systemMode === this.SYSTEM_MODES.PENG;
      const isIndexPage = this.$route.name === 'PENG_Index';

      if (isPengMode && isIndexPage) {
        return {
          backgroundImage: `url(${this.peng_index_bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }
      }
      return {}
    },
    user() {
      return this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
    }
  },
};
</script>
