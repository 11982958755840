<template>
  <el-container
    class="app-wrapper"
    direction="vertical"
  >
    <app-navbar
      class="nav-container"
      @toggleSidebar="toggleSidebar"
    />
    <el-container>

      <component :is="currentSidebar" ref="sidebar" class="sidebar-container" />

<!--      <app-sidebar-->
<!--        ref="sidebar"-->
<!--        class="sidebar-container"-->
<!--      />-->
      <app-main class="main-container" />
    </el-container>
  </el-container>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue';
// import AppSidebar from './components/AppSidebar.vue';
import AppMain from './components/AppMain.vue';

import AppSidebarDS from '../ds/layouts/AppSidebar.vue';
import AppSidebarPENG from '../peng/layouts/AppSidebar.vue';


export default {
  name: 'Layout',
  components: {
    AppNavbar,
    // AppSidebar,
    AppSidebarDS,
    AppSidebarPENG,
    AppMain,
  },
  data() {
    return {};
  },
  computed: {
    currentSidebar() {
      // 根据当前路由确定加载哪个侧边栏组件
      const routeName = this.$route.name;
      // 如果路径中有 /peng/ 则加载 AppSidebarPENG
      console.log('>>>routeName', routeName);
      if (routeName.includes('PENG')) {
        return 'AppSidebarPENG';
      } else {
        return 'AppSidebarDS';
      }
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$refs.sidebar) {
        this.$refs.sidebar.toggleSidebar();
      }
    },
    switchLang(lang = 'zh-CN') {
      console.log('>>>lang', lang);
      const locale = lang === 'en' ? 'en' : 'zh-CN';
      this.$locale.use(locale);
      localStorage.setItem('PENGUIN-FE_LANGUAGE', locale);
      this.$store.dispatch('changeLang', locale);
    },
  },
};
</script>
