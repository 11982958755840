import Vue from 'vue';
import Router from 'vue-router';
import VueRouter from 'vue-router';
import indexDS from './indexDS';
import indexPENG from './indexPENG';
import Login from '@/views/auth/TheLogin.vue';
import AppLayout from '@/views/layouts/AppLayout.vue';
import store from '@/store';
import { SYSTEM_MODES, PENG_PATH_PREFIX, getSystemModeByPath } from '@/config/system';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        hidden: true,
      },
    },
    {
      path: '/',
      redirect: { name: 'Index' },
      meta: {
        hidden: true,
      },
    },
    {
      path: '/index',
      component: AppLayout,
      redirect: { name: 'Index' },
      children: [
        {
          path: '/index',
          name: 'Index',
          component: () => import('@/views/ds/common/VHomepage.vue'),
          meta: {
            icon: 'home',
            auth: ['root', 'admin', 'general', '', 'ops'],
          },
        },
      ],
    },
    {
      path: '/users',
      component: AppLayout,
      redirect: { name: 'Users' },
      children: [
        {
          path: 'index',
          name: 'Users',
          component: () => import('@/views/ds/admin/TheUsers.vue'),
          meta: {
            icon: 'people_outline',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
    {
      path: '/403',
      meta: {
        hidden: true,
      },
      component: Login,
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/error/404.vue'),
      meta: {
        hidden: true,
      },
    },
    {
      path: '/peng/404',
      name: 'PENG_404',
      component: () => import('@/views/error/404.vue'),
      meta: {
        hidden: true,
      },
    },
    ...indexDS,
    ...indexPENG,
    {
      path: '*',
      redirect: to => {
        const currentSystemMode = getSystemModeByPath(to.path);
        return currentSystemMode === SYSTEM_MODES.PENG ? '/peng/404' : '/404';
      }
    }
  ],
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // 如果是登录页面或404页面，直接放行
  if (to.name === 'Login' || to.name === '404' || to.name === 'PENG_404') {
    next();
    return;
  }

  const currentSystemMode = getSystemModeByPath(to.path);
  store.dispatch('setSystemMode', currentSystemMode);
  
  // 只在 PENG 模式下添加 /peng/ 前缀
  if (currentSystemMode === SYSTEM_MODES.PENG && !to.path.startsWith(PENG_PATH_PREFIX)) {
    next(PENG_PATH_PREFIX + to.path);
  } else {
    next();
  }
});

export default router;
