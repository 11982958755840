import AppLayout from '@/views/layouts/AppLayout.vue';


export default [

    {
      path: '/tasks_manage',
      name: 'TasksManage',
      component: AppLayout,
      redirect: { name: 'Publish' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/tasks_manage/publish',
          name: 'Publish',
          component: () => import('@/views/ds/admin/schedule/ThePublish.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/schedule',
          name: 'Schedule',
          component: () => import('@/views/ds/admin/schedule/TheSchedule.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/handle',
          name: 'Handle',
          component: () => import('@/views/ds/admin/schedule/TheHandle.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/test',
          name: 'Test',
          component: () => import('@/views/ds/admin/schedule/TheTest.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        }
      ],
    },

    {
      path: '/requirements_manage',
      name: 'RequirementsManage',
      component: AppLayout,
      redirect: { name: 'Requirements' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/requirements_manage/dev_delivery_overview',
          name: 'Overview',
          component: () => import('@/views/ds/admin/requirement/TheOverview.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/dev_tracking',
          name: 'Tracking',
          component: () => import('@/views/ds/admin/requirement/TheDevTracking.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/requirements',
          name: 'Requirements',
          component: () => import('@/views/ds/admin/requirement/TheRequirements.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/sync',
          name: 'Sync',
          component: () => import('@/views/ds/admin/requirement/TheSyncRequirement.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/tools',
          name: 'Tools',
          component: () => import('@/views/ds/admin/requirement/TheTools.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/masterlist',
          name: 'Masterlist',
          component: () => import('@/views/ds/admin/requirement/TheMasterlist.vue'),
          meta: {
            icon: 'insert_drive_file',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/search',
          name: 'Search',
          component: () => import('@/views/ds/admin/requirement/TheSearch.vue'),
          meta: {
            icon: 'update',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },

    {
      path: '/delivery_manage',
      name: 'DeliveryManage',
      component: AppLayout,
      redirect: { name: 'DeliveryStatus' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/delivery_manage/delivery_status',
          name: 'DeliveryStatus',
          component: () => import('@/views/ds/admin/delivery/TheDelivery.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/auto_commit',
          name: 'AutoCommit',
          component: () => import('@/views/ds/admin/delivery/TheAutoCommit.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/repair',
          name: 'Repair',
          component: () => import('@/views/ds/admin/delivery/TheRepair.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/duty',
          name: 'DutyReport',
          component: () => import('@/views/ds/admin/delivery/TheDutyReport.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },

      ],
    },

    // 2024-06-07 感觉wiki里的内容都相对过时了，没人维护，而且容易分裂飞书wiki。先下线吧。
    // {
    //   path: '/wiki',
    //   name: 'Wiki',
    //   component: AppLayout,
    //   redirect: { name: 'ShowWiki' },
    //   meta: {
    //     hasMulSub: true,
    //   },
    //   children: [
    //     {
    //       path: '/wiki/wiki',
    //       name: 'ShowWiki',
    //       component: () => import('@/views/admin/wiki/TheWiki.vue'),
    //       meta: {
    //         icon: 'add',
    //         auth: ['root', 'admin', 'general', ''],
    //       },
    //     },
    //   ],
    // },

    {
      path: '/response',
      name: 'Response',
      component: AppLayout,
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/response/group',
          name: 'ResponseGroup',
          component: () => import('@/views/ds/admin/response/TheGroup.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', '', 'ops'],
          },
        },
        {
          path: '/response/query',
          name: 'ResponseQuery',
          component: () => import('@/views/ds/admin/response/TheQuery.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', '', 'ops'],
          },
        },
        {
          path: '/response/result',
          name: 'ResponseResult',
          component: () => import('@/views/ds/admin/response/TheResult.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', '', 'ops'],
          },
        },
      ],
    },

    {
      path: '/spider',
      name: 'Spider',
      component: AppLayout,
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/spider/mgmt',
          name: 'SpiderMgmt',
          component: () => import('@/views/ds/admin/spider/TheMgmt.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
  ]

