<template>
  <el-header :class="navbarClass" class="flex-box space-btw">
    <span
      class="toggle-btn"
      @click.stop="$emit('toggleSidebar')"
    >
      <img
        src="@/assets/baseline_menu_white_24dp.png"
        alt=""
      >
    </span>

    <span
      class="cursor-pointer el-header__brand"
      @click="handleLogoClick"
    >
      <img
        :src=getLogo()
        alt="Logo"
        class="h-7"
      >
    </span>


    <Marquee v-if="is_manager()" :lists="marquee_list"></Marquee>


    <!--banner 位置 背景图 用于特殊节日 -->
<!--    <div style="display: inline-flex; margin: 0; width: 90%; max-height: 60px; position: absolute; right: 0; top:0">-->
<!--      <el-image-->
<!--        style="width: 100%; max-height: 60px"-->
<!--        :src="custom_banner"-->
<!--        fit="fill">-->
<!--      </el-image>-->
<!--    </div>-->

    <!-- menu -->
<!--    <navbar-menu></navbar-menu>-->

    <!-- avatar -->
    <div class="flex-box">
      <system-mode-switch class="mr-4"/>
      <base-langbar />
      <el-dropdown>
        <span class="el-dropdown-link userinfo flex-box">
          <span class="el-header__userinfo">
            <img
              class="avatar flex-box"
              :src=getAvatar()
              alt="Avatar"
            >
            <span>{{ user && user.username || user.email }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </span>
        <el-dropdown-menu v-if="!notLogin" slot="dropdown">
          <el-dropdown-item @click.native="changePassword">
            {{ $t('common.account') }}
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            {{ $t('common.logout') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <change-password-dialog ref="changePasswordRef" />
  </el-header>
</template>

<script>
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import BaseLangbar from '@/components/widgets/BaseLangbar.vue';
import SystemModeSwitch from '@/components/widgets/SystemModeSwitch.vue';
import NavbarMenu from './menus/TheIndex.vue';
import logo_svg from '@/assets/logo-login.svg';
import default_avatar from '@/assets/avatar.svg';
import custom_avatar from '@/assets/avatar2.svg';
import custom_banner from '@/assets/chrismas/ms-banner.svg';
import Marquee from '@/components/Marquee.vue';
import { SYSTEM_MODES, PENG_PATH_PREFIX } from '@/config/system';

export default {
  name: 'AppNavBar',
  components: {
    ChangePasswordDialog,
    BaseLangbar,
    SystemModeSwitch,
    NavbarMenu,
    Marquee,
  },
  data() {
    return {
      custom_banner,
      marquee_list: [
      ],
      SYSTEM_MODES,
    };
  },
  computed: {
    systemMode() {
      return this.$store.state.common.systemMode;
    },
    navbarClass() {
      return this.systemMode === this.SYSTEM_MODES.PENG ? 'navbar-red' : '';
    },
    activeMenu() {
      return this.$route.name;
    },
    user() {
      return this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
    },
    notLogin() {
      return Object.keys(this.user).length === 0
    }
  },
  methods: {
    roleShow(route) {
      if (!route.meta) {
        return true;
      }

      // hack, there is no user when logout
      if (!this.user || route.meta.hidden) {
        return false;
      }

      const { auth } = route.meta;
      return auth ? (!auth.length && !this.user.role) || auth.includes(this.user.role) : !auth;
    },
    logout() {
      this.$auth.logout().then(() => {
        this.$autoCommitHttpService.logout();
        if (this.systemMode === this.SYSTEM_MODES.PENG) {
          this.$router.push({ name: 'PENG_Login' });
        } else {
          this.$router.push({ name: 'Login' });
        }
      });
    },
    changePassword() {
      this.$refs.changePasswordRef.goToChangePassword();
    },
    getAvatar() {
      if (this.user.username === 'pengshuxia'){
        return custom_avatar
      }
      return default_avatar
    },
    getLogo() {
      return logo_svg
    },
    is_manager() {
      let visible_list = ['liangzhichong', 'pengshuxia',  'wuling']
      return visible_list.includes(this.user.username)
    },
    handleLogoClick() {
      const isPengMode = this.$store.state.common.systemMode === this.SYSTEM_MODES.PENG;
      if (isPengMode) {
        this.$router.push({ name: 'PENG_Index' });
      } else {
        this.$router.push({ name: 'Index' });
      }
    },
  },
};
</script>

<style>
.navbar-red {
  background-color: rgba(200, 15, 15, 0.82) !important;
}
</style>
